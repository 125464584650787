import React, { useRef, useEffect } from 'react'
import { useField } from '@unform/core'
import { FormGroup, Label, Input as ReactstrapInput } from 'reactstrap'
import InputMask from 'react-input-mask';

export default function Input({ name, label, mask, children, style, ...rest }) {
  const inputRef = useRef(null)

  const { fieldName, defaultValue, registerField, error } = useField(name)

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: ref => {
        if (ref && ref.current)
          return ref.current.value
        return null
      },
      setValue: (ref, value) => {
        ref.current.value = value
      },
      clearValue: ref => {
        ref.current.value = ''
      },
    })
  }, [fieldName, registerField])

  return (
    <FormGroup style={style}>
      <Label htmlFor={fieldName} className="col-form-label">{label}</Label>
      <ReactstrapInput
        id={fieldName}
        innerRef={inputRef}
        defaultValue={defaultValue}
        className="form-control"
        mask={mask && mask}
        {...rest}
        tag={mask && InputMask}
      />
      {children}
      {error && <span className="error">{error}</span>}
    </FormGroup>      
  )
}