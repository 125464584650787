import { useField } from '@unform/core';
import React, { useRef, useEffect, useState } from 'react'

import CurrencyInput from 'react-currency-input-field';
import { FormGroup, Label } from 'reactstrap';

function toNumber(value) {
  return parseFloat(`${value}`.replace(/\./g, '').replace(',', '.'));
}

export default function FCurrencyInput({ name, label, ...rest }) {
  const { registerField, fieldName, defaultValue, error } = useField(name);
  const inputRef = useRef({ value: defaultValue });
  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = inputValue;
    }
  }, [inputValue]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
      getValue: (ref) => {
        if (ref && ref.value) {
          return toNumber(ref.value)
        }
        return null
      },
      setValue: (_, value) => {
        setInputValue(value);
      },
      clearValue: () => {
        setInputValue("");
      }
    });
  }, [fieldName, registerField]);

  return (
    <FormGroup>
      <Label htmlFor={fieldName} className="col-form-label">{label}</Label>
      <CurrencyInput
        name={name}
        defaultValue={defaultValue}
        value={inputValue}
        onValueChange={(value, name) => {
          setInputValue(value)
        }}
        decimalsLimit={2}
        decimalScale={2}
        className="form-control"
        prefix="R$ "
        decimalSeparator=","
        groupSeparator="."
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </FormGroup>
  )
}