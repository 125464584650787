import { Form } from '@unform/web';
import React, { useEffect, useRef, useState } from 'react';
import { Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

import Breadcrumbs from '../../layout/breadcrumb';
import Input from '../Input';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}  

const Profile = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth();
  const accountFormRef = useRef();
  const modalPasswordFormRef = useRef();
  const [perfilSubmitLoading, setPerfilSubmitLoading] = useState(false);
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [card, setCard] = useState(null);

  useEffect(() => {
    api.get('/users/info/me').then(response => {
      accountFormRef.current.setData({
        email: response.data.email,
        name: response.data.name,
        birth_date: response.data.birth_date,
        phone: response.data.phone
      })
      setCard(response.data.card)
    })
  }, [])

  const toggle = () => setModal(!modal);

  const handleAccountFormSubmit = async (data) => {
    try {

      const schema = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('Obrigatório'),
      });
  
      await schema.validate(data, {
        abortEarly: false,
      });          

      setPerfilSubmitLoading(true);

      await api.put(`/users/${user.id}`, { email: data.email })

      toast.success('Perfil atualizado com sucesso!')

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          accountFormRef.current.setErrors(validationErrors);
        }
      } else {
        setPerfilSubmitLoading(false);
        toast.error(err.message);
      }        
    } finally {
      setPerfilSubmitLoading(false);
    }
  }

  const handlePasswordFormSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        current_password: Yup.string().required('A senha atual é obrigatória'),
        password: Yup.string().required('A nova senha é obrigatória'),
        password_confirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'As senhas não conferem'
        ),        
      });
  
      await schema.validate(data, {
        abortEarly: false,
      });                

      setUpdatePasswordLoading(true);

      await api.put(`/users/${user.id}/password`, data)

      toast.success('Senha alterada com sucesso!');

      toggle();
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          modalPasswordFormRef.current.setErrors(validationErrors);
        }
      } else {
        setPerfilSubmitLoading(false);
        toast.error(err.message);
      }           
    } finally {
      setUpdatePasswordLoading(false);
    }
  }

  const handleCreditCardSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      toast.error(error.message)
    } else {
      const response = await api.post('/users/me/payment-methods/attach', { payment_method_id: paymentMethod.id })
      toast.success('Cartão de crédito atualizado com sucesso')
      setCard(response.data)
    }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle}>
        <Form ref={modalPasswordFormRef} onSubmit={handlePasswordFormSubmit}>
          <ModalHeader toggle={toggle}>Redefinir Senha</ModalHeader>
          <ModalBody>
            <Input
              name="current_password"
              label="Senha atual"
              type="password"
            />
            <Input
              name="password"
              label="Nova senha"
              type="password"
            />
            <Input
              name="password_confirmation"
              label="Repita a nova senha"
              type="password"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggle}>Cancelar</Button>{' '}
            <Button color="primary" disabled={updatePasswordLoading} type="submit">
              { updatePasswordLoading ? 'CARREGANDO...' : 'Enviar' }
            </Button>            
          </ModalFooter>          
        </Form>
      </Modal>    
      <Breadcrumbs parent="Minha Conta" title="Meu perfil" />
      <Container fluid>
        <Row>
          <Col sm="12">
            <Form ref={accountFormRef} onSubmit={handleAccountFormSubmit}>
              <Card>
              <CardHeader>
                <h4>Meus dados</h4>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col sm="6">
                    <Input
                      label="Nome"
                      placeholder="Digite aqui seu nome..."
                      name="name"
                      disabled
                    />
                  </Col>
                  <Col sm="6">
                    <Input
                      label="Data de Nascimento"
                      name="birth_date"
                      disabled
                      type="date"
                    />
                  </Col>     
                </Row>
                <Row>                                  
                  <Col sm="6">
                    <Input
                      label="E-mail"
                      placeholder="seu@email.com"
                      name="email"
                    />
                  </Col>    
                  <Col sm="6">
                    <Input
                      label="Celular"
                      name="phone"
                      placeholder="(99) 9 9999-9999"
                      //mask="(99) 9 9999-9999"
                    />
                  </Col>                                 
                </Row>                
              </CardBody>
              <CardFooter>
                <div className="text-right">
                  <Button outline color="primary" onClick={toggle}>Redefinir senha</Button>
                  <button
                    className="btn btn-primary"
                    type="submit"
                    style={{ marginLeft: '30px' }}
                    disabled={perfilSubmitLoading}>
                    { perfilSubmitLoading ? 'CARREGANDO...' : 'Salvar alterações' }
                  </button>
                </div>
              </CardFooter>              
              </Card>
            </Form>
          </Col>               
          <Col sm="12">
            <form onSubmit={handleCreditCardSubmit}>
              <Card>
                <CardHeader>
                  <h4>Cartão de Crédito</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    {!card ? (
                      <Col sm="3">
                        <CardElement
                          options={{
                            hidePostalCode: true,
                          }}
                        />
                      </Col>                      
                    ) : (
                      <Col sm="3">
                        <div>
                          <span><b>{capitalizeFirstLetter(card.brand)}: **** **** **** {card.last4}</b></span>
                          <p style={{ color: 'gray' }}>Vence em {String(card.exp_month).padStart(2, '0')}/{card.exp_year}</p>
                        </div>
                      </Col>
                    )}
                  </Row> 
                </CardBody>
                {!card && (
                  <CardFooter className="text-right">
                    <button className="btn btn-primary" type="submit" disabled={!stripe}>Salvar</button>
                  </CardFooter>              
                )}
              </Card>
            </form>
          </Col>      
        </Row>
      </Container>
    </>
  );
}

export default Profile;