import Account from '../components/account'
import FinancialReport from '../components/financial-report'
import MegaOption from '../components/forms/form-control/megaOption'
import RobotForm from '../components/robot-form'
import Robots from '../components/robots'
import Strategies from '../components/strategies'
import Transactions from '../components/transactions'
import Filters from '../components/filters'
import StrategyForm from '../components/strategy-form'
import UserEdit from '../components/users/userEdit'
import General from '../components/widgets/general'

export const routes = [
  { path: '/', Component: Robots },
  { path: '/strategies', Component: Strategies },
  { path: '/strategies/form/:id?', Component: StrategyForm },
  { path: '/transactions', Component: Transactions },
  { path: '/filters', Component: Filters },
  { path: '/robots', Component: Robots },
  { path: '/robots/:id/financial-report', Component: FinancialReport },
  { path: '/robots/form/:id?', Component: RobotForm },
  { path: '/account', Component: Account },
  { path: '/mega', Component:UserEdit },
  { path: '/dashboard/default', Component: Robots }
]
