import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
  const [data, setData] = useState(() => {
    const token = localStorage.getItem('@firebot:token');
    const id = localStorage.getItem('@firebot:user_id');
    const name = localStorage.getItem('@firebot:user_name');

    if (token && id && name) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return { token, user: { id, name } };
    }
    return {};
  });

  const signIn = useCallback(async ({ email, password }) => {
    const response = await api.post('/sessions', {
      email,
      password,
    });

    localStorage.setItem('@firebot:user_id', response.data.id)
    localStorage.setItem('@firebot:user_name', response.data.name.split(' ')[0])
    localStorage.setItem('@firebot:token', response.data.token)

    api.defaults.headers.authorization = `Bearer ${response.data.token}`;

    setData({ token: response.data.token, user: { id: response.data.id, name: response.data.name } });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@firebot:token');
    localStorage.removeItem('@firebot:user_id');
    localStorage.removeItem('@firebot:user_name');

    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error(' useAuth must be used within an authProvider ');
  }
  return context;
}
export { AuthProvider, useAuth };