const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

const toCents = (value) => {
  value = `${value}`.replace(/[^\d.-]/g, '');
  if (value && value.includes('.')) {
    value = value.substring(0, value.indexOf('.') + 3);
  }

  return value ? Math.round(parseFloat(value) * 100) : 0;
};

const toMoney = (value) => {
  value = `${value}`.replace(/[^\d.-]/g, '');
  value = parseFloat(value);
  return value ? value / 100 : 0;
};

const currencyFormat = (value) =>
  (value || 0).toLocaleString('pt-br', {
    style: 'currency',
    currency: 'BRL',
  });

module.exports = {
  toCents,
  toMoney,
  currencyFormat,
  formatPrice
};
