import { Form } from '@unform/web';
import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {Container,Row,Col,FormGroup,Button} from 'reactstrap' 
import Input from '../../components/Input';
import * as Yup from 'yup';
import { toast, ToastContainer } from 'react-toastify';
import api from '../../services/api';

const Forgetpwd = (props) => {

    const formRef = useRef();
    const [loading, setLoading] = useState(false);

    const onSubmit = async (data) => {
      try {
        
        const schema = Yup.object().shape({
          email: Yup.string().email('E-mail inválido').required('Obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await api.post('/forgot', { email: data.email })
        
        props.history.push('/login');

        setTimeout(() => toast.success('Enviamos as instruções de recuperação de senha para o seu e-mail!'), 200)
        
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message;
            });
            formRef.current.setErrors(validationErrors);
          }
        } else {
          setLoading(false);
          toast.error(err.message);
        }        
      } finally {
        setLoading(false);
      }
    }
    
    return (
      <Container fluid={true}>
      <ToastContainer />
      <Row>
          <Col xs="12">     
            <div className="login-card">
              <div>
                <div><a className="logo" href="#javascript"><img className="img-fluid for-light" src={require("../../assets/images/logo/login.png")} alt="looginpage"/><img className="img-fluid for-dark" src={require("../../assets/images/logo/logo_dark.png")} alt="looginpage"/></a></div>
                <div className="login-main"> 
                  <Form ref={formRef} onSubmit={onSubmit} className="theme-form">
                    <h4>Recupere sua senha</h4>
                    <Input
                      name="email"
                      label="Informa o seu e-mail"
                      placeholder="seu@email.com"
                    />
                    <FormGroup className="mb-0">
                      <Button color="primary" className="btn-block" type="submit" disabled={loading}>
                        { loading ? 'CARREGANDO...' : 'Recuperar' }
                      </Button>
                    </FormGroup>
                    <p className="mt-4 mb-0">{"Se lembra da senha?"}<Link className="ml-2" to="/login">Voltar</Link></p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
}

export default Forgetpwd;