import * as Yup from 'yup';

export const schema = Yup.object().shape({
  name: Yup.string().required('Obrigatório'),
  gain_goal: Yup.string().nullable(),
  monthly_gain_goal: Yup.string().nullable(),
  loss_limit: Yup.string().nullable(),
  monthly_loss_limit: Yup.string().nullable(),
  entry: Yup.number().required('Obrigatório'),
  base_bet_amount: Yup.string().required('Obrigatório'),
  steps: Yup.array().of(
    Yup.object().shape({
      path_id: Yup.number().typeError('Obrigatório').required('Obrigatório'),
      bet_amount: Yup.string().required('Obrigatório'),
      order: Yup.number().required('Obrigatório'),
    })
  ),
  martingales: Yup.array().of(
    Yup.object().shape({
      order: Yup.number().required('Obrigatório'),
      base_bet_amount: Yup.string().required('Obrigatório'),
      steps: Yup.array().of(
        Yup.object().shape({
          path_id: Yup.number()
            .typeError('Obrigatório')
            .required('Obrigatório'),
          bet_amount: Yup.string().required('Obrigatório'),
          order: Yup.number().required('Obrigatório'),
        })
      ),
    })
  ),
});
