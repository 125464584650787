import React, { useEffect, useRef, useState } from 'react';
// import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../layout/breadcrumb';

import { Form as UnForm } from '@unform/web'

import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Media, Form, Label, Input, FormGroup, InputGroup, InputGroupText, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import CountUp from 'react-countup';
import { ArrowDown, ArrowUp, Cpu, Database, PlusCircle } from 'react-feather';
import api from '../../services/api'
import FInput from '../../components/Input';
import FReactSelect from '../../components/FReactSelect'
import * as Yup from 'yup';
import { toast } from 'react-toastify'
import { formatPrice, toCents, toMoney } from '../../util/format'
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { capitalizeFirstLetter } from '../account/profile';
import FCurrencyInput from '../CurrencyInput';
import { Scope } from '@unform/core';
import { schema } from './schema'
import queryString from 'query-string';

const StrategyForm = (props) => {
  const formRef = useRef();
  const modalFormRef = useRef();
  const history = useHistory();
  const { id } = useParams();
  const location = useLocation();

  const [filters, setFilters] = useState([]);
  const [entryOn, setEntryOn] = useState(null);
  const [playSteps, setPlaySteps] = useState([]);
  const [martingales, setMartingales] = useState([]);
  const [isMartingaleFlagActive, setIsMartingaleFlagActive] = useState('no');
  const [stopWhenLosingARound, setStopWhenLosingARound] = useState(false);
  const [paths, setPaths] = useState([]);

  const [percentUponGainGoal, setPercentUponGainGoal] = useState(0);
  const [monthlyPercentUponGainGoal, setMonthlyPercentUponGainGoal] = useState(0);
  const [minimumCapital, setMinimumCapital] = useState(0);
  const [loading, setLoading] = useState(false);

  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isModalSubmitLoading, setIsModalSubmitLoading] = useState(false)

  const calculateAllTotalCosts = () => {
    const data = formRef.current.getData();

    const martingales = data.martingales || []
    const steps = data.steps || []

    const totalBetOnBaseSteps = steps
      .map(s => s.bet_amount || 0)
      .reduce((a, b) => a + b, 0);

    formRef.current.setFieldValue(`base_bet_amount`, totalBetOnBaseSteps);

    martingales.forEach((martingale, index) => {
      const totalBetOnMartingale = martingale.steps
        .map(s => s.bet_amount || 0)
        .reduce((a, b) => a + b, 0);

      formRef
        .current
        .setFieldValue(`martingales[${index}].base_bet_amount`, totalBetOnMartingale);
    })

    const getExpectedProfit = (betAmount, payment, totalOnCurrentStep) => {
      const betAmountNumber = Number(betAmount) || 0
      const premium = betAmountNumber + betAmountNumber * payment
      return premium - totalOnCurrentStep
    }

    steps.forEach((step, index) => {
      const path = paths.find(p => p.id === step.path_id)
      if (path) {
        const total = getExpectedProfit(step.bet_amount, path.payment, totalBetOnBaseSteps)
        formRef.current.setFieldValue(`steps[${index}].expected_profit`, total);
      }
    })

    martingales.forEach((martingale, martingaleIndex) => {
      martingale.steps.forEach((step, stepIndex) => {
        const path = paths.find(p => p.id === step.path_id)
        if (path) {
          const total = getExpectedProfit(step.bet_amount, path.payment, martingale.base_bet_amount)
          formRef.current.setFieldValue(
            `martingales[${martingaleIndex}].steps[${stepIndex}].expected_profit`,
            total
          )
        }
      })
    })

    setTimeout(() => {
      calculateMinimumCapital();
    }, 1000)
    
  }

  const toggleModal = () => setIsModalOpened(!isModalOpened);

  function calculateMinimumCapital() {
    if (formRef.current) {
      const {
        base_bet_amount,
        martingales: currentMartingales,
      } = formRef.current.getData();

      let amountMartingalesBet = 0;
      let amountBase = 0;

      if (base_bet_amount) {
        amountBase = base_bet_amount;
      }

      if (currentMartingales && currentMartingales.length > 0) {
        amountMartingalesBet = currentMartingales
          .map((m) => m.base_bet_amount || 0)
          .reduce((a, b) => a + b, 0);
      }

      return setMinimumCapital(amountBase + amountMartingalesBet);
    }
    return 0;
  }    

  useEffect(() => {
    const fetchData = async () => {
      const userId = localStorage.getItem('@firebot:user_id')
      const responseFilters = await api.get('admin/filters', {
        params: {
          user_id: userId
        }
      });

      const responsePaths = await api.get('admin/paths', { params: { type: 'bet' } })
      setPaths(responsePaths.data);

      setFilters([...responseFilters.data, ...responsePaths.data]);

      if (id) {
        const strategyResponse = await api.get(`admin/strategies/${id}`)
        const strategy = {
          ...strategyResponse.data,
          best_scenario:  toMoney(strategyResponse.data.best_scenario),
          worst_scenario:  toMoney(strategyResponse.data.worst_scenario),
          expected_scenario:
            toMoney(strategyResponse.data.expected_scenario),
          robot_cost: toMoney(strategyResponse.data.robot_cost),
          infra_cost: toMoney(strategyResponse.data.infra_cost),
          minimum_capital: toMoney(strategyResponse.data.minimum_capital),
          percent_upon_profit:
            toMoney(strategyResponse.data.percent_upon_profit),
          gain_goal: toMoney(strategyResponse.data.gain_goal),
          monthly_gain_goal:
            toMoney(strategyResponse.data.monthly_gain_goal),
          loss_limit: toMoney(strategyResponse.data.loss_limit),
          monthly_loss_limit:
            toMoney(strategyResponse.data.monthly_loss_limit),
          recommended_capital:
            toMoney(strategyResponse.data.recommended_capital),
          delay: strategyResponse.data.delay || 0,
          last_year_income:
            toMoney(strategyResponse.data.last_year_income),
          last_year_assertiveness_rate:
            toMoney(strategyResponse.data.last_year_assertiveness_rate),
        };

        const sortedMartingales = strategyResponse.data.martingales.sort(
          (a, b) => a.order - b.order
        );

        const sortedMartingalesWithSortedSteps = sortedMartingales.map(
          (martingale) => ({
            ...martingale,
            steps: martingale.steps
              .map((step) => ({
                ...step,
                bet_amount: toMoney(step.bet_amount),
              }))
              .sort((a, b) => a.order - b.order),
            base_bet_amount: toMoney(martingale.base_bet_amount),
          })
        );

        strategy.martingales = sortedMartingalesWithSortedSteps;

        strategy.steps = strategy.steps.map((step) => ({
          ...step,
          bet_amount: toMoney(step.bet_amount),
        }));

        const steps = strategyResponse.data.steps.sort(
          (a, b) => a.order - b.order
        );

        const entryOnValue = strategyResponse.data.entry + 1
        setEntryOn(entryOnValue);
        strategy.entry_1 = entryOnValue

        setPlaySteps(steps);

        setMartingales(sortedMartingalesWithSortedSteps);

        if (
          !sortedMartingalesWithSortedSteps ||
          sortedMartingalesWithSortedSteps.length === 0
        ) {
          setIsMartingaleFlagActive('no');
        } else {
          setIsMartingaleFlagActive('yes');
        }

        setTimeout(() => formRef.current.setData(strategy), 200)

        setStopWhenLosingARound(
          strategyResponse.data.stop_when_losing_a_round
        );
      } else {
        setPlaySteps([
          {
            order: 0,
            id: (Math.random() * 10000000).toFixed(0),
          },
        ]);
        setMartingales([
          {
            order: 0,
            steps: [],
          },
        ]);
        setTimeout(() => {
          formRef.current.setFieldValue(`entry`, 0);
          formRef.current.setFieldValue(`steps[0].order`, 0);
          formRef.current.setFieldValue(`martingales[0].order`, 0);
        }, 100);      
      }
      setTimeout(() => {
        calculateAllTotalCosts()
        calculateMinimumCapital()
      }, 1000);
    }
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    handleGainGoalChange();
    handleMonthlyGainGoalChange();
  }, [minimumCapital]); //eslint-disable-line

  const handleSubmit = async (data) => {

    calculateMinimumCapital();

    const hasAllRequiredAttrs = (step) => step.hasOwnProperty('bet_amount');

    const steps = data.steps
      .filter((step) => hasAllRequiredAttrs(step))
      .map((step, index) => ({
        ...step,
        bet_amount: toCents(step.bet_amount),
        order: index,
      }));

    const finalData = {
      ...data,
      steps: [...steps],
    };

    if (data.martingales && data.martingales.length > 0) {
      let newMartingales = data.martingales.filter((martingale) => martingale.hasOwnProperty('base_bet_amount')); //eslint-disable-line

      newMartingales = newMartingales.map((martingale, martingaleIndex) => {
        martingale.steps = martingale.steps.map((step, index) => {
          return {
            ...step,
            bet_amount: toCents(step.bet_amount),
            order: index
          };
        });

        return {
          ...martingale,
          order: martingaleIndex,
          base_bet_amount: toCents(martingale.base_bet_amount),
        };
      });

      finalData.martingales = [...newMartingales];
    }

    try {
      await schema.validate(finalData, {
        abortEarly: false,
      });

      const payload = {
        ...finalData,
        best_scenario: toCents(data.best_scenario),
        worst_scenario: toCents(data.worst_scenario),
        expected_scenario: toCents(data.expected_scenario),
        robot_cost: toCents(data.robot_cost),
        infra_cost: toCents(data.infra_cost),
        // percent_upon_profit: toCents(data.percent_upon_profit),
        minimum_capital: toCents(minimumCapital),
        gain_goal: toCents(data.gain_goal),
        monthly_gain_goal: toCents(data.monthly_gain_goal),
        loss_limit: toCents(data.loss_limit),
        monthly_loss_limit: toCents(data.monthly_loss_limit),
        validated: true,
        archived: false,
        template: false,
        stop_when_losing_a_round: stopWhenLosingARound,
        // numbers_sequence: numbersInSequence.numbers,
        last_year_income: toCents(data.last_year_income),
        last_year_assertiveness_rate: toCents(data.last_year_assertiveness_rate),
        minimum_days: 0,
        risk: 'low',
        user_id: localStorage.getItem('@firebot:user_id'),
        roulette_name: 'Roleta Brasileira',
        roulette_link: 'https://launcher.betfair.com/?gameId=live-rolet-brasileria-cptl&channel=casino&returnURL=https%253A%252F%252Fcasino.betfair.com%252Fpt-br%252Fp%252Fcassino-ao-vivo&launchProduct=gaming&RPBucket=gaming&mode=real&dataContext=web&dataChannel=ecasino&switchedToPopup=true'
      }

      setLoading(true);

      const { newVersion } = queryString.parse(location.search);

      if (newVersion) {
        await api.post(`admin/strategies/${id}/new-version`, payload);
      } else {
        await api.post('admin/strategies', payload);
      }

      history.push('/strategies');

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
          console.log(validationErrors)
        }
      } else {
        toast.error(err.error);
      } 
    } finally {
      setLoading(false);
    }   
  }

  const handleFilterSubmit = async (data, { resetForm }) => { 
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Obrigatório'),
        numbers: Yup.string().required('Obrigatório')
      });

      data.user_id = localStorage.getItem('@firebot:user_id')

      await schema.validate(data, {
        abortEarly: false,
      });

      setIsModalSubmitLoading(true)

      if (data.id) {
        await api.put(`admin/filters/${data.id}`, data);
      } else {
        await api.post('admin/filters', data);
      }

      toast.success('Operação realizada com sucesso')

      toggleModal()

      const userId = localStorage.getItem('@firebot:user_id')

      const responseFilters = await api.get('admin/filters', {
        params: {
          user_id: userId
        }
      });

      const responsePaths = await api.get('admin/paths', { params: { type: 'bet' } })
      setPaths(responsePaths.data);

      setFilters([...responseFilters.data, ...responsePaths.data]);

      formRef.current.setFieldValue('numbers_sequence', data.numbers)

      resetForm()
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          modalFormRef.current.setErrors(validationErrors);
        }
      } else {
        toast.error(err.error);
      }
    } finally {
      setIsModalSubmitLoading(false)
    }
  }

  const removeStep = (step, type, martingale) => {
    if (type === 'step') {
      const newSteps = playSteps.filter((playStep) => playStep !== step);
      setPlaySteps(newSteps);
    } else if (type === 'martingale') {
      const oldMartingales = martingales;
      const newSteps = martingale.steps.filter((playStep) => playStep !== step);
      martingale.steps = newSteps;
      const index = oldMartingales.findIndex((m) => m === martingale);
      oldMartingales[index] = martingale;
      setMartingales([...oldMartingales]);
    }
  } 

  const addNewPlayStep = () => {
    const newSteps = [
      ...playSteps,
      {
        order: playSteps.length,
        id: Math.random(),
      },
    ];
    setPlaySteps(newSteps);

    newSteps.forEach((step, i) => {
      setTimeout(
        () => formRef.current.setFieldValue(`steps[${i}].order`, step.order),
        100
      );
    });
  }

  const addNewMartingaleStep = (index) => {
    const martingale = martingales[index];

    martingale.steps = [
      ...martingale.steps,
      {
        order: martingale.steps.length,
        id: new Date().getTime(),
      },
    ];

    martingales[index] = martingale;

    const newList = martingales.map((m, i) => (i === index ? martingale : m));

    setMartingales(newList);

    newList.forEach((item) => {
      item.steps.forEach((step, i) => {
        setTimeout(
          () =>
            formRef.current.setFieldValue(
              `martingales[${index}].steps[${i}].order`,
              step.order
            ),
          200
        );
      });
    });
  }  

  const removeMartingale = (martingale) => {
    const newMartingales = martingales.filter((m) => m !== martingale);
    setMartingales([...newMartingales]);
  }

  const handleGainGoalChange = () => {
    const { gain_goal: value } = formRef.current.getData();

    if (value && value > 0) {
      const percent = Number((value * 100) / minimumCapital).toFixed(2);
      setPercentUponGainGoal(percent);
    }
  }

  const handleMonthlyGainGoalChange = () => {
    const { monthly_gain_goal: value } = formRef.current.getData();

    if (value && value > 0) {
      const percent = Number((value * 100) / minimumCapital).toFixed(2);
      setMonthlyPercentUponGainGoal(percent);
    }
  }  

  const handleCheckboxStopWhenLosingARoundChange = () => {
    setStopWhenLosingARound(!stopWhenLosingARound);
  }  

  const renderSteps = (
    first,
    removeStepFunction,
  ) => {
    return (
      <Row>
        <Col sm="6">
          <FReactSelect
            label="Números a apostar"
            name="path_id"
            options={paths.map(f => ({
              label: f.name,
              value: f.id
            }))}
            onBlur={calculateAllTotalCosts}
          />
        </Col>                 
        <Col sm="2">
          <FCurrencyInput
            label="Valor da aposta"
            name="bet_amount"
            onBlur={calculateAllTotalCosts}
          />
        </Col>                              
        <Col sm="2">
          <FCurrencyInput
            name="expected_profit"
            label="Lucro esperado"
            disabled
          />
        </Col>
        <Col sm="2">
          <Button
            color="danger"
            style={{ marginTop: '35px', width: '100%' }}
            disabled={first}
            onClick={removeStepFunction}
          >Remover números</Button>
        </Col>
      </Row>      
    )
  }

  const addNewMartingale = () => {
    const newMartingales = [
      ...martingales,
      {
        order: martingales.length,
        steps: [],
      },
    ];
    setMartingales(newMartingales);

    newMartingales.forEach((martingale, i) => {
      setTimeout(
        () =>
          formRef.current.setFieldValue(
            `martingales[${i}].order`,
            martingale.order
          ),
        100
      );
    });
  }

  return (
    <>
      <Modal isOpen={isModalOpened} toggle={toggleModal}>
        <UnForm ref={modalFormRef} onSubmit={handleFilterSubmit}>
          <ModalHeader toggle={toggleModal}>Criar gatilho de entrada</ModalHeader>
          <ModalBody>
            <FInput
              name="name"
              label="Nome do gatilho"
            />
            <FInput
              name="numbers"
              label="Números do gatilho"
            />
            <p>
              Informe os números separados por vígula.
              <br />
              Ex.: 2,10,17,31
            </p>
            <FInput type="hidden" name="id" style={{ height: '0', width: '0' }} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>Cancelar</Button>{' '}
            <Button color="primary" disabled={isModalSubmitLoading} type="submit">
              { isModalSubmitLoading ? 'CARREGANDO...' : 'Salvar' }
            </Button>            
          </ModalFooter>          
        </UnForm>
      </Modal>    
      <Breadcrumbs parent="Robô de Cassino" title="Estratégia de roleta" />
        <Container fluid>
        <UnForm onSubmit={handleSubmit} ref={formRef}>
          <Row>
             <Col sm={12}>
                <Card>
                  <CardHeader>
                    <h4>Configurações da estratégia</h4>
                  </CardHeader>
                  <CardBody>
                    <Row className="mb-4">
                      <Col sm="8">
                        <FInput
                          label="Nome da estratégia"
                          name="name"
                          type="name"
                        />
                      </Col>             
                    </Row>
                    <Row className="mb-5">
                      <Col sm="12" className="mb-2">
                        <h5>Gatilho de entrada</h5>
                      </Col>
                      <Col sm="12" className="mb-2">
                        <p>
                          Você pode definir um gatilho de entrada, assim o robô irá aguardar cair os números informados no filtro abaixo para entrar.
                        </p>
                      </Col>
                      <Col sm="8">
                        <FReactSelect
                          label="Aguardar os números abaixo cairem para entrar"
                          name="numbers_sequence"
                          options={filters.map(f => ({
                            label: [f.name, <br />, "Números: ", f.numbers],
                            value: f.numbers
                          }))}
                        />
                      </Col> 
                      <Col sm="2">
                        <FInput
                          name="entry"
                          label="Quantas vezes?"
                          onChange={(e) => setEntryOn(parseInt(e.target.value, 10) + 1)}
                        />
                      </Col>
                      <Col sm="2">
                        <FInput
                          disabled
                          name="entry_1"
                          label="Jogará no evento"
                          value={entryOn}
                        />
                      </Col>
                      <Col sm="12">
                        <Button color="primary" outline onClick={toggleModal}>Criar gatilho</Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="mb-2">
                        <h5>Aposta base inicial</h5>
                      </Col>                
                      <Col sm="3">
                        <FCurrencyInput
                          label="Custo total da aposta base"
                          name="base_bet_amount"
                          placeholder=""
                          disabled
                        />
                      </Col>
                      <Col sm="9" className='align-self-center'>
                        <span class="badge bg-primary">Valor mínimo: R$ 0,50</span>
                        <span class="badge bg-primary">Valor máximo: R$ 12.500,00</span>
                      </Col>
                    </Row>              
                    {playSteps.map((step, i) => (
                      <Scope
                        key={step.id}
                        path={`steps[${i}]`}
                      >
                        {renderSteps(
                          playSteps.length === 1,
                          () => removeStep(step, 'step')
                        )}                      
                      </Scope>
                    ))}
                    <Row>
                      <Col sm="12" className="mt-2">
                        <Button color="primary" onClick={addNewPlayStep}>
                          Adicionar aposta em mais números
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12" className="mb-4">
                        <h5>Você deseja utilizar martingales?</h5>
                      </Col>                
                      <Col sm="12" className="d-flex" style={{ marginLeft: '18px' }}>
                        <div className="radio radio-primary">
                          <Input
                            id="radio11"
                            type="radio"
                            name="radio1"
                            value="yes"
                            onChange={(e) => setIsMartingaleFlagActive(e.target.value)}
                            checked={isMartingaleFlagActive === 'yes'}
                          />
                          <Label for="radio11">Utilizar martingales</Label>
                        </div>
                        <div className="radio ml-5">
                          <Input
                            id="radio12"
                            type="radio"
                            name="radio1"
                            value="no"
                            onChange={(e) => setIsMartingaleFlagActive(e.target.value)}
                            checked={isMartingaleFlagActive === 'no'}
                          />
                          <Label for="radio12">Não utilizar martingales</Label>
                        </div>                
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {isMartingaleFlagActive === 'yes' && (
                <>
                  {martingales.map((martingale, i) => (
                    <Col sm={12} key={i}>
                      <Scope path={`martingales[${i}]`}>
                        <Card>
                          <CardBody>
                            <Row>
                              <Col sm="12" className="mb-4">
                                <h5>Dados do martingale {i + 1}</h5>
                              </Col>
                              <Col sm="3">
                                <FCurrencyInput
                                  label={`Custo total do martingale ${i + 1}`}
                                  name="base_bet_amount"
                                  placeholder=""
                                  disabled
                                />
                              </Col>
                              <Col sm="9" className='align-self-center'>
                                <span class="badge bg-primary">Valor mínimo: R$ 0,50</span>
                                <span class="badge bg-primary">Valor máximo: R$ 12.500,00</span>
                              </Col>
                            </Row>
                            {martingale.steps.map((step, idx) => (
                              <Scope
                                key={step.id}
                                path={`steps[${idx}]`}
                              >
                                {renderSteps(
                                  martingale.steps.length === 1,
                                  () =>
                                    removeStep(
                                      step,
                                      'martingale',
                                      martingale
                                    ),
                                )}
                              </Scope>
                            ))}
                            <Row className="mt-2">
                              <Col sm="12">
                                <hr />
                              </Col>
                              <Col sm="12">
                                <Button
                                  color="primary"
                                  onClick={() => addNewMartingaleStep(i)}
                                >
                                  Adicionar aposta em mais números
                                </Button>
                                <Button
                                  color="danger"
                                  style={{ float: 'right' }}
                                  onClick={() => removeMartingale(martingale)}
                                >
                                  Remover este martingale
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Scope>
                    </Col>
                  ))}
                  <Col sm={12} className="d-flex justify-content-center mb-4">
                    <Button
                      color="primary"
                      className="btn-lg"
                      onClick={addNewMartingale}
                    >+ Adicionar martingale</Button>
                  </Col>
                </>
              )}
              <Col sm={12}>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="12" className="mb-2">
                        <h5>Dados operacionais da estratégia</h5>
                      </Col>
                      <Col sm="3" className="mb-4">
                        <FCurrencyInput
                          label="Capital mínimio para a estratégia"
                          value={minimumCapital}
                          name="test"
                          disabled
                        />
                      </Col>  
                      <Col sm="3">
                        <FInput
                          type="time"
                          label="Hora de início"
                          name="start_hour"
                        />       
                      </Col>
                      <Col sm="3">
                        <FInput
                          type="time"
                          label="Hora de parada"
                          name="end_hour"
                        />       
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="mb-2">
                        <h5>Metas da estratégia</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <FCurrencyInput
                          label="Meta de ganho diário"
                          name="gain_goal"
                          onBlur={handleGainGoalChange}
                        />
                      </Col>
                      <Col sm="1">
                        <Input style={{ marginTop: '35px' }} value={percentUponGainGoal + "%"} disabled />
                      </Col>
                      <Col sm="3">
                        <FCurrencyInput
                          label="Meta de ganho mensal"
                          name="monthly_gain_goal"
                          onBlur={handleMonthlyGainGoalChange}
                        />
                      </Col>   
                      <Col sm="1">
                        <Input style={{ marginTop: '35px' }} value={monthlyPercentUponGainGoal + "%"} disabled />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="3">
                        <FCurrencyInput
                          label="Limite de perda diária"
                          name="loss_limit"
                        />
                      </Col>
                      <Col sm="3">
                        <FCurrencyInput
                          label="Limite de perda mensal"
                          name="monthly_loss_limit"
                        />
                      </Col>
                      <Col sm="12">
                        <div className="checkbox checkbox-primary">
                          <input id="checkbox-primary-1" type="checkbox" checked={stopWhenLosingARound} />
                          <Label htmlFor="checkbox-primary-1"
                            onClick={handleCheckboxStopWhenLosingARoundChange}
                          >
                            Ativar função "Parar ao Perder"
                          </Label>
                        </div>
                      </Col>
                      <Col sm="12">
                        <p>A função “Parar ao perder”, faz com que o robô continue jogando
                          até acontecer a primeira perda. Após isso, o robô irá parar no
                          dia e só continuará no dia seguinte, a partir do horário
                          pré-definido pelo usuário e caso não tenha horário pré-definido,
                          iniciará as 00:00h.
                        </p>
                      </Col>
                    </Row>                    
                  </CardBody>
                </Card>
              </Col>                
              <Col sm="12" className="d-flex justify-content-between mb-5 mt-3">
                <Button outline className="btn-lg">Cancelar</Button>            
                <Button color="primary" className="btn-lg">
                  {loading ? 'SALVANDO ESTRATÉGIA...' : 'Salvar'}
                </Button>
             </Col>      
          </Row>                   
        </UnForm>
      </Container>
    </>
  );
}

export default StrategyForm;