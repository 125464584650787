import React,{useState, useRef, useEffect} from 'react';
import {Container,Row,Col,Input, Label,Button} from 'reactstrap'
import { Form } from '@unform/web'
import * as Yup from 'yup';
import {toast, ToastContainer} from 'react-toastify'
import FInput from '../../components/Input'
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { useParams } from 'react-router';
import { differenceInYears } from 'date-fns/esm';

const Register = (props) => {
  const { signIn } = useAuth()
  const params = useParams()

  useEffect(() => {
    const { token } = params
    if (token !== '434a175b-d3f3-4bb9-84ed-dab0e31ebae3') {
      props.history.push('/login');
    }
  }, [params, props.history])

  const formRef = useRef(null);

  const [togglePassword,setTogglePassword] = useState(false);
  const [hasAcceptedTerms, setAcceptedTerms] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (data) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Obrigatório'),
        birth_date: Yup.string().required('Obrigatório'),
        phone: Yup.string().required('Obrigatório'),
        email: Yup.string().email('E-mail inválido').required('Obrigatório'),
        password: Yup.string().required('Obrigatório')
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      if (!hasAcceptedTerms) {
        toast.error('Ops! Você não aceitou os termos de uso!')
        return;
      }

      const yearsOld = differenceInYears(new Date(), new Date(data.birth_date))

      if (yearsOld < 18) {
        toast.error('É necessário ter mais de 18 anos')
        return;
      }

      setLoading(true);

      await api.post('users', data)

      await signIn({
        email: data.email,
        password: data.password,
      });

      props.history.push('/robots');
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      } else {
        setLoading(false);
        toast.error(err.message);
      }
    }      
  }
    
  const HideShowPassword  = (tPassword) => {
    setTogglePassword(!tPassword)
  }

  return (
  <Container fluid={true} className="p-0">
    <ToastContainer/>
    <Row>
      <Col xs="12">     
        <div className="login-card">
          <div>
            <div>
              <a className="logo" href="#javascript">
                Firebot
              </a>
            </div>
            <div className="login-main"> 
              <Form className="theme-form" ref={formRef} onSubmit={handleSubmit}>
                <h4>Crie sua conta</h4>
                <p>Preencha as informações abaixo para criar sua conta</p>
                <FInput
                  name="name"
                  label="Nome completo"
                  placeholder="João da Silva"
                />
                <FInput
                  name="birth_date"
                  label="Data de Nascimento"
                  placeholder="10/10/2000"
                  type="date"
                />
                <FInput
                  name="phone"
                  mask="(99) 9 9999-9999"
                  label="Celular"
                  placeholder="(99) 9 9999-9999"
                />
                <FInput
                  name="email"
                  label="E-mail"
                  placeholder="joao@gmail.com"
                />
                <FInput
                  name="password"
                  label="Senha"
                  placeholder="****"
                  type={togglePassword ?  "text" : "password" }
                >
                  <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                </FInput>
                <div className="form-group mb-0">
                  <div className="checkbox ml-3">
                    <Input id="checkbox1" type="checkbox" onChange={e => setAcceptedTerms(!hasAcceptedTerms)}/>
                    <Label className="text-muted" for="checkbox1">{"Li e aceito os"}<a target='_blank' className="ml-2" href="https://www.firebet.com.br/termos-e-condicoes">Termos de Uso</a></Label>
                  </div>
                  <Button color="primary" className="btn-block" type="submit">{loading ? 'CARREGANDO' : 'Criar conta'}</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  </Container>
  );
}

export default Register;