import React, { useRef, useEffect } from 'react';
import ReactSelect from 'react-select';
import { useField } from '@unform/core';
import { Label } from 'reactstrap';
import { FormGroup } from 'react-bootstrap';

export default function FReactSelect({ label, name, ...rest }) {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        const result = ref.getValue().map(item => item.value)
        return result.length > 0 ? result[0] : null
      },
      setValue: (ref, value) => {
        if (rest.isMulti && Array.isArray(value)) {
          const items = ref?.props?.options?.filter((option) =>
            value.includes(option.value)
          );
          ref.select.setValue(items);
        } else {
          const item = ref?.props?.options?.filter(
            (option) => option.value === value
          );
          if (item && item.length > 0) {
            ref.setValue(item);
          }
        }
      },
    });
  }, [fieldName, registerField, rest.isMulti]);

  return (
    <FormGroup>
      <Label htmlFor={fieldName} className="col-form-label">{label}</Label>
      <ReactSelect
        defaultValue={defaultValue}
        ref={selectRef}
        classNamePrefix="react-select"
        placeholder="Selecione..."
        {...rest}
      />
      {error && <span className="error">{error}</span>}
    </FormGroup>          
  );
};