import { Cpu, Clipboard, DollarSign, Target } from 'react-feather'

export const MENUITEMS = [
    {
        menutitle:"Roleta Cassino",
        menucontent:"Gerencie seus robôs de roleta",
        Items:[
            { path: '/robots', icon: Cpu, title: 'Meus Robôs', type: 'link' },
            { path: '/strategies', icon: Clipboard, title: 'Estratégias', type: 'link' },
            { path: '/filters', icon: Target, title: 'Gatilhos de Entrada', type: 'link' },
        ]
    },
    {
        menutitle:"Conta Firebet",
        menucontent:"Gerenciar Sua Conta",
        Items:[
            { path: '/transactions', icon: DollarSign, title: 'Extrato Financeiro', type: 'link' },
        ]
    },
]