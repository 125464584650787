import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Table, FormGroup, Nav, NavItem, NavLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { Target, Info, CheckCircle, PlusCircle, MoreVertical } from 'react-feather';
import {Link, useHistory} from 'react-router-dom'
import api from '../../services/api';
import { format, parseISO } from 'date-fns';
import { formatPrice } from '../../util/format';
import { toast } from 'react-toastify';

const status = {
  'paused_by_time': {
    class: 'light text-dark',
    label: 'Fora do horário'
  },
  'reached_gain_goal': {
    class: 'success',
    label: 'Meta diária batida'
  },
  'reached_monthly_gain_goal': {
    class: 'success',
    label: 'Meta mensal batida'
  },
  'reached_loss_limit': {
    class: 'danger',
    label: 'Limite de perda hoje'
  },
  'reached_monthly_loss_limit': {
    class: 'danger',
    label: 'Limite de perda'
  },
  'stopped_by_one_lose': {
    class: 'danger',
    label: 'Limite de perda hoje'
  }
}

const TagStatus = (props) => {
  const robot = props.robot;
  if (robot.robot_status === 'started') {
    if (!robot.robot_secondary_status) {
      return <span className="badge badge-primary">Operando agora</span>
    }
    const statusObject = status[robot.robot_secondary_status]
    return <span className={`badge badge-${statusObject.class}`}>{statusObject.label}</span>
  } else if (robot.robot_status === 'waiting_review') {
    return <span className="badge badge-dark">Aguardando revisão</span>  
  }
  return <span className="badge badge-light text-dark">Desligado</span>
}

const getStatus = (robot) => {
  if (robot.robot_status === 'started') {
    if (!robot.robot_secondary_status) {
      return 'Ligado'
    }
    const statusObject = status[robot.robot_secondary_status]
    return statusObject.label
  } else if (robot.robot_status === 'waiting_review') {
    return 'Aguardando revisão'
  }
  return 'Desligado'
}

const Robots = (props) => {
  const history = useHistory();
  const [filter, setFilter] = useState('')
  const [robots, setRobots] = useState([]);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false)

  const fetchRobots = () => {
    api.get('robots').then(response => setRobots(response.data))
  }

  useEffect(() => {
    fetchRobots()

    let attemps = 0

    const interval = setInterval(() => {
      attemps++

      const shouldUpdate = localStorage.getItem('should_update')
      if (shouldUpdate) {
        setIsUpdateModalOpen(true)
      }      

      if (attemps >= 10 || shouldUpdate) {
        clearInterval(interval)
      }

    }, 5000)
  }, [])

  useEffect(() => {
    api.get('robots', {
      params: {
        filter
      }
    }).then(response => setRobots(response.data))
  }, [filter])

  const downloadUpdate = () => {
    const updateLink = localStorage.getItem('update_link')
    window.open(updateLink, '_blank');
    localStorage.removeItem('should_update')
    localStorage.removeItem('update_link')
    window.close()
  }

  const goToStatistics = (id) => {
    history.push(`/robots/${id}/financial-report`)
  }

  const goToConfigurations = (id) => {
    history.push(`/robots/form/${id}`)
  }

  const goToNewRobot = async () => {
    history.push('/robots/form')
  }

  const toggleRobot = async (robot) => {
    let robot_status = null;
    if (robot.robot_status === 'started') {
      robot_status = 'stopped';
    } else if (robot.robot_status === 'stopped') {
      const balance = Number(localStorage.getItem('@firebot:user_balance'))
      if (balance <= 0) {
        toast.error('Você não possui saldo para ligar este robô')
        return
      }
      robot_status = 'started';
    }
    await api.put(`/robots/${robot.id}`, { robot_status })
    fetchRobots()
  }

  const showCloseWarn = () => {
    return robots.find(robot => robot.robot_status === 'started' && !robot.robot_secondary_status)
  }

  const redirectToStripeBillingPortal = async () => {
    try {
      const response = await api.get('/users/stripe/billing-portal-session')
      window.open(response.data.url, '_blank')
    } catch (error) {
      console.log(error)
      toast.error("Falha ao processar requisição")
    }
  }

  return (
    <Fragment>
      <Breadcrumb parent="Robô de Cassino" title="Meus robôs" />
      <Modal isOpen={isUpdateModalOpen}>
        <ModalHeader>Nova atualização disponível</ModalHeader>
        <ModalBody>
          <span>Clique no botão abaixo para baixar a atualização.</span>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" type="submit" onClick={() => downloadUpdate()}>
            Baixar atualização
          </Button>            
        </ModalFooter>          
      </Modal>          
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  <Nav tabs className="border-tab">
                    <NavItem><NavLink style={{ cursor: 'pointer' }} className={filter === '' ? 'active' : ''} onClick={() => setFilter('')}><Target />Todos</NavLink></NavItem>
                    <NavItem><NavLink style={{ cursor: 'pointer' }} className={filter === 'started' ? 'active' : ''} onClick={() => setFilter('started')}><Info />Rodando</NavLink></NavItem>
                    <NavItem><NavLink style={{ cursor: 'pointer' }} className={filter === 'stopped' ? 'active' : ''} onClick={() => setFilter('stopped')}><CheckCircle />Desativado</NavLink></NavItem>
                  </Nav>
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} onClick={goToNewRobot}>
                     <PlusCircle />Criar novo robô
                    </Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
              <Card>
                  <div className="table table-hover table-responsive">
                      <Table size="lg">
                          <thead>
                              <tr>
                                  <th scope="col"></th>
                                  <th scope="col">Conta</th>
                                  <th scope="col">Estratégia</th>
                                  <th scope="col">Assinatura</th>
                                  <th scope="col">Status</th>
                                  <th scope="col"></th>
                              </tr>
                          </thead>
                          <tbody>
                              {robots.map(robot => (
                                <tr>
                                  <td style={{ minWidth: '222px', verticalAlign: 'middle' }}>
                                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'left', alignItems: 'center' }}>
                                      <img width={40} height={40} className="rounded" src="https://legit.org/wp-content/uploads/2020/12/betfair.png" alt="" />
                                      <p style={{ margin: '0' }}>
                                        <strong>betfair.com</strong>
                                        <br />Roleta Brasileira
                                      </p>
                                    </div>
                                  </td>
                                  <td style={{ verticalAlign: 'middle', minWidth: '205px' }}>
                                    {robot.login}
                                    <br />Saldo conta: {robot.exchange_balance === null ? <span class="txt-danger">Não coletado</span> : <span class="txt-primary">{formatPrice(robot.exchange_balance/100)}</span>}
                                  </td>
                                  <td style={{ verticalAlign: 'middle', minWidth: '255px' }}>
                                    {robot.strategy.name}
                                    <br />Banca mínima: {formatPrice(robot.strategy.minimum_capital/100)}
                                  </td>
                                  <td style={{ verticalAlign: 'middle', minWidth: '158px' }}>
                                    {new Date(robot.current_period_end) < new Date() ? (
                                      <strong className='txt-danger'>Vencida</strong>
                                    ) : (
                                      <>
                                        Vence em
                                        <br />
                                        <strong>{format(parseISO(robot.current_period_end), 'dd/MM/yyyy')}</strong>
                                      </>
                                    ) }
                                    
                                  </td>
                                  <td style={{ verticalAlign: 'middle' }}>
                                    <TagStatus robot={robot} />
                                  </td>
                                  <td style={{ verticalAlign: 'middle', minWidth: '262px' }}>
                                    {new Date(robot.current_period_end) < new Date() ? (
                                      <Button
                                        color='primary'
                                        onClick={redirectToStripeBillingPortal}>
                                        Renovar assinatura
                                      </Button>
                                    ) : (
                                    <div style={{ display: 'flex', gap: '16px', justifyContent: 'flex-end' }}>
                                      <button
                                        onClick={() => toggleRobot(robot)}
                                        className={`btn  btn-${robot.robot_status === 'stopped' ? 'success' : 'outline-danger'}`}
                                      >
                                        {robot.robot_status === 'stopped' ? 'Ligar robô' : 'Desligar robô'}
                                      </button>
                                      <UncontrolledDropdown>
                                        <DropdownToggle outline color="primary" style={{ height: '36px', padding: '6px' }}>
                                          <MoreVertical  />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem onClick={() => goToStatistics(robot.id)}>
                                            Extrato
                                          </DropdownItem>
                                          {(robot.robot_status === 'stopped' || robot.robot_secondary_status) && (
                                            <DropdownItem onClick={() => goToConfigurations(robot.id)}>
                                              Configurações
                                            </DropdownItem>
                                          )}
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </div>
                                    )}
                                  </td>
                                </tr>        
                              ))}
                          </tbody>
                      </Table>
                  </div>
              </Card>
          </Col>
      </Row>         
        {/* <div className="row">
          {showCloseWarn() && (
            <Col sm={12}>
              <div class="alert alert-warning inverse bg-light">
                <i class="icon-alert"></i> <strong>Enquanto o robô estiver operando, não feche o programa sem antes clicar para desliga-lo. Fechar o programa durante a operação poderá acarretar em perdas financeiras.</strong>
              </div>   
            </Col>
          )}
          {robots.map(robot => (
            <div key={robot.id} className="col-xl-6 xl-100">
              <div className="card default-panel-height">
                <div className="job-search">
                  <div className="card-body pb-0">
                    <RibbonStatus robot={robot} />
                    <div className="media">
                      <img className="img-40 img-fluid m-r-20" src="https://legit.org/wp-content/uploads/2020/12/betfair.png" alt="" />
                      <div className="media-body">
                        <h6 className="f-w-600">{robot.login}</h6>
                        <p>www.betfair.com - Roleta Brasileira</p>
                      </div>
                    </div>
                    <p>
                      {robot.strategy.start_hour.length > 0 && (
                        <div className="alert alert-warning inverse">
                          <i className="icon-alert"></i> Este robô possui horário específico para operar, portanto, deve ser ligado com pelo menos <strong>10 minutos</strong> de antecedência ao horário de funcionamento.
                        </div>
                      )}
                      
                      <div className="row my-3">
                        <div className="col">
                          <strong>Saldo em conta:</strong> <span className="text-success">
                            {robot.exchange_balance === null ? "Não coletado" : formatPrice(robot.exchange_balance/100)}
                          </span>
                        </div>
                        <div className="col">
                          <strong>Banca mínima para operar:</strong> <span className="text-warning">
                            {formatPrice(robot.strategy.minimum_capital/100)}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-3">
                        <div className="col-12 text-center">
                          <ul className="list-group">
                            <li className="list-group-item list-group-item-secondary"><strong>Horário de Operação</strong></li>
                            {robot.strategy.start_hour.length === 0 ? (
                              <li className="list-group-item">Opera em qualquer horário</li>    
                            ) : (
                              <li className="list-group-item">
                                De {robot.strategy.start_hour}h à {robot.strategy.end_hour}h
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 text-center">
                          <h6>Vencimento da assinatura: {format(parseISO(robot.current_period_end), 'dd/MM/yyyy')}</h6>
                        </div>
                      </div>

                    </p>
                  </div>
                  <div className="card-footer text-center">
                    <button
                      onClick={() => toggleRobot(robot)}
                      className={`btn btn-${robot.robot_status === 'stopped' ? 'success' : 'danger'} m-b-10 mr-2`}
                    >
                      {robot.robot_status === 'stopped' ? 'Ligar robô' : 'Desligar robô'}
                    </button>
                    <button
                      onClick={() => goToStatistics(robot.id)}
                      className="btn btn-outline-primary m-b-10 mr-2"
                    >
                      Extrato de operações
                    </button>
                    {(robot.robot_status === 'stopped' || robot.robot_secondary_status) && (
                      <button
                        className="btn btn-outline-primary m-b-10"
                        onClick={() => goToConfigurations(robot.id)}
                      >
                        Alterar configurações
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>           */}
      </Container>
    </Fragment>
  );
}

export default Robots;