import React from 'react';

import {BrowserRouter,Switch,Route,Redirect} from 'react-router-dom'
import App from '../components/app'
import { CSSTransition,TransitionGroup } from 'react-transition-group'
import {routes} from '../route';

import Register from '../pages/authentication/register';

// Signin page
import Signin from '../auth/signin'

// Authentication
import Login from "../pages/authentication/login"
import LoginWithBgImage from "../pages/authentication/loginWithBgImage"
import LoginWithBgVideo from "../pages/authentication/loginWithBgVideo"
import LoginWithValidation from "../pages/authentication/loginwithValidation"
import RegisterWithBgImage from "../pages/authentication/registerWithBgImage"
import RegisterWithBgVideo from "../pages/authentication/registerWithBgVideo"
import UnlockUser from "../pages/authentication/unlockUser"
import Forgetpwd from "../pages/authentication/forgetpwd"
import Resetpwd from "../pages/authentication/resetpwd"

// Error page
import Error400 from "../pages/errors/error400"
import Error401 from "../pages/errors/error401"
import Error403 from "../pages/errors/error403"
import Error404 from "../pages/errors/error404"
import Error500 from "../pages/errors/error500"
import Error503 from "../pages/errors/error503"

// Comming soo
import Comingsoon from "../pages/comingSoon/comingsoon"
import ComingsoonImg from "../pages/comingSoon/comingsoonImg"
import ComingsoonVideo from "../pages/comingSoon/comingsoonVideo"

// Maintenanc
import Maintenance from "../pages/maintenance"

import Callback from '../auth/callback'
import { useAuth } from '../hooks/auth';

const Routes = () => {
  const { user } = useAuth();

  return (
    <BrowserRouter basename={`/`}>
    <Switch>

      <Route  path="/login" component={Signin} />
      <Route  path={`${process.env.PUBLIC_URL}/pages/auth/login`} component={Login}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg1`} component={LoginWithBgImage}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/auth/loginWithBgImg2`} component={LoginWithBgVideo}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/auth/loginWithValidation`} component={LoginWithValidation}></Route>
      <Route  path="/signup/:token" component={Register}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg1`} component={RegisterWithBgImage}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/auth/signupWithImg2`} component={RegisterWithBgVideo}></Route>
      <Route  path="/auth/password/forget" component={Forgetpwd}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/auth/unlockUser`} component={UnlockUser}></Route>
      <Route  path="/auth/password/reset/:token" component={Resetpwd}></Route>

      <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error400`} component={Error400}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error401`} component={Error401}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error403`} component={Error403}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error404`} component={Error404}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error500`} component={Error500}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/errors/error503`} component={Error503}></Route>
      
      <Route  path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoon`} component={Comingsoon}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoonImg`} component={ComingsoonImg}></Route>
      <Route  path={`${process.env.PUBLIC_URL}/pages/comingsoon/comingsoonVideo`} component={ComingsoonVideo}></Route>

      <Route  path={`${process.env.PUBLIC_URL}/pages/maintenance`} component={Maintenance}></Route>
      
      <Route  path={`${process.env.PUBLIC_URL}/callback`} render={() => <Callback/>} />
      
      {user ?
      
      <App>

      <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
          return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard/default`} />)
      }} /> 
      <TransitionGroup>
          {routes.map(({ path, Component }) => (
            <Route key={path}  exact  path={path}>
                {({ match }) => (
                    <CSSTransition 
                      in={match != null}
                      timeout={100}
                      unmountOnExit>
                      <div><Component/></div>
                    </CSSTransition> 
                )}
            </Route>
            ))}
      </TransitionGroup> 
      
      </App>
      :
      <Redirect to="/login" />
      }      
    </Switch>
  </BrowserRouter>    
  )
}

export default Routes;