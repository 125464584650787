import React, { useEffect, useRef, useState } from 'react';
// import { Col, Container, Row } from 'reactstrap';
import Breadcrumbs from '../../layout/breadcrumb';

import { Form as UnForm } from '@unform/web'

import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Button, Media, Form, Label, Input, FormGroup } from 'reactstrap'
import CountUp from 'react-countup';
import { ArrowDown, ArrowUp, Cpu, Database } from 'react-feather';
import api from '../../services/api'
import FInput from '../../components/Input';
import * as Yup from 'yup';
import { toast } from 'react-toastify'
import { formatPrice } from '../../util/format'
import { useHistory, useParams } from 'react-router';
import { capitalizeFirstLetter } from '../account/profile';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const InternalComponent = (props) => {
  const formRef = useRef();
  const history = useHistory();
  const params = useParams();
  const stripe = useStripe();
  const elements = useElements();
  
  const [loading, setLoading] = useState(false);
  const [strategy, setStrategy] = useState(null);
  const [strategies, setStrategies] = useState([]);
  const [initializedRobot, setInitializedRobot] = useState(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [card, setCard] = useState(null);
  const [strategyCost, setStrategyCost] = useState(0);

  useEffect(() => {
    api.get('strategy-cost').then(strategyCostResponse => {
      setStrategyCost(strategyCostResponse.data.total);
      api.get('strategies').then(response => {
        setStrategies(response.data);
        if (params.id) {
          api.get(`robots/${params.id}`).then(({ data }) => {
            setInitializedRobot(data);
            setStrategy(response.data.find(strategy => strategy.id === data.strategy_id));
          })      
        }
        api.get('/users/info/me').then(response => setCard(response.data.card))        
    })
    })  
  }, []); // eslint-disable-line

  useEffect(() => {
    if (params.id && initializedRobot) {
      formRef.current.setData({
        login: initializedRobot.login,
        password: initializedRobot.password
      })
    }
  }, [strategy, params.id, initializedRobot])

  useEffect(() => {
    setIsSaveButtonDisabled(loading);
  }, [loading])

  const handleStrategySelection = (e) => {
    const selected = Number(e.target.value)
    if (selected === 0) {
      setStrategy(null);
    } else {
      setStrategy(strategies.find(strategy => strategy.id === selected))
    }
  }

  const onBlurCredentials = () => {
    const login = formRef.current.getFieldValue('login');
    const password = formRef.current.getFieldValue('password');

    setIsSaveButtonDisabled(!login.length || !password.length);
  }

  const handleSubmit = async (data) => {
    try {
      
      const schema = Yup.object().shape({
        login: Yup.string().required('Obrigatório'),
        password: Yup.string().required('Obrigatório')
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setLoading(true);

      if (params.id) {
        await api.put(`robots/${params.id}`, {
          login: data.login,
          strategy_id: strategy.id
        });
        toast.success('Robô atualizado com sucesso!');
      } else {
        if (!card) {
          if (!stripe || !elements) {
            return;
          }
      
          const cardElement = elements.getElement(CardElement);
      
          const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
      
          if (error) {
            toast.error(error.message)
            return
          } else {
            const response = await api.post('/users/me/payment-methods/attach', { payment_method_id: paymentMethod.id })
            setCard(response.data)
          }
        }

        await api.post('robots', {
          platform: 'Betfair',
          type: 'Roleta',
          login: data.login,
          password: data.password,
          strategy_id: strategy.id
        });
        toast.success('Robô criado com sucesso!');
      }

      history.push('/robots')

    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
            toast.error(`${error.path === 'password' ? 'Senha' : 'Login'} - ${error.message}`)
          });
          formRef.current.setErrors(validationErrors);
          console.log(validationErrors)
        }
      } else {
        toast.error(err.error);
      } 
    } finally {
      setLoading(false);
    }   
  }

  return (
    <>
      <Breadcrumbs parent="Meus robôs" title={params.id ? 'Editar robô' : 'Criar novo robô'} />
        <Container fluid>
        <UnForm onSubmit={handleSubmit} ref={formRef}>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>Configure a casa de aposta</h5>
                  <span>Insira os dados de acesso da plataforma de apostas</span>
                </CardHeader>
                <CardBody className="megaoptions-border-space-sm">
                  <div className="mega-inline">
                    <Row>
                      <Col sm="12">
                        <Card>
                          <Media className="p-20">
                            <div className="radio radio-primary mr-3">
                              <Input id="radio14" type="radio" name="radio1" value="option1" checked readOnly/>
                              <Label for="radio14"></Label>
                            </div>
                            <Media body style={{ verticalAlign: 'middle' }}>
                              <div style={{ display: 'flex', gap: '16px', justifyContent: 'left', alignItems: 'center' }}>
                                <img width={60} height={60} className="rounded" src="https://legit.org/wp-content/uploads/2020/12/betfair.png" alt="" />
                                <p style={{ margin: '0' }}>
                                  <h4 className="mega-title-badge mb-0">Betfair</h4>
                                  <span>www.betfair.com</span>
                                </p>
                              </div>
                            </Media>
                          </Media>
                        </Card>
                      </Col>
                    </Row>
                  </div>                
                </CardBody>
              </Card>                            
              </Col>          
          </Row>          
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h4>Configure a estratégia</h4>
                </CardHeader>
                <CardBody>
                <Row>
                  {((params.id && strategy) || !params.id ) && (
                  <Col sm="12" className="mb-3">
                    <FormGroup>
                      <Label htmlFor="exampleFormControlSelect9">Selecione a estratégia</Label>
                      <Input
                        type="select"
                        name="stragegy"
                        className="form-control digits"
                        onChange={handleStrategySelection}
                        defaultValue={strategy?.id}>
                          <option value="0">Selecione uma estratégia...</option>
                          {strategies.map(s => (
                            <option key={s.id} value={s.id}>{s.commercial_name || s.name}</option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>                    
                  )}
                  </Row>
                  {strategy && (
                    <Row>
                      <Col sm="4">
                        <Card className="o-hidden">
                          <CardBody className="card-body statistics-wrapper">
                            <div className="statistics-header">
                              <div className="widget-icon bg-custom-light custom-danger"><i className="icofont icofont-arrow-down font-danger"></i></div>
                              <h6 className="statistic-name">Pior cenário</h6>
                            </div>
                            <div className="statistics-footer">
                              <h5>- <span className="counter">{formatPrice(strategy.worst_scenario / 100)}</span></h5>
                              <span className="font-roboto"><span className="font-danger text-bold">Possibilidade de loss</span></span>                          
                            </div>
                          </CardBody>                      
                        </Card>                      
                      </Col>
                      <Col sm="4">
                        <Card className="o-hidden">
                          <CardBody className="card-body statistics-wrapper">
                            <div className="statistics-header">
                              <div className="widget-icon bg-custom-light custom-success"><i className="icofont icofont-ui-remove font-success"></i></div>
                              <h6 className="statistic-name">Cenário esperado</h6>
                            </div>
                            <div className="statistics-footer">
                              <h5>+ <span className="counter">{formatPrice(strategy.expected_scenario / 100)}</span></h5>
                              <span className="font-roboto"><span className="font-success text-bold">
                                +{((strategy.expected_scenario * 100) / strategy.minimum_capital).toFixed(0)}% ao mês
                              </span></span>                          
                            </div>
                          </CardBody>                      
                        </Card>                      
                      </Col>
                      <Col sm="4">
                        <Card className="o-hidden">
                          <CardBody className="card-body statistics-wrapper">
                            <div className="statistics-header">
                              <div className="widget-icon bg-custom-light custom-success"><i className="icofont icofont-arrow-up font-success"></i></div>
                              <h6 className="statistic-name">Melhor cenário</h6>
                            </div>
                            <div className="statistics-footer">
                              <h5>+ <span className="counter">{formatPrice(strategy.best_scenario / 100)}</span></h5>
                              <span className="font-roboto"><span className="font-success text-bold">
                                +{((strategy.best_scenario * 100) / strategy.minimum_capital).toFixed(0)}% ao mês</span></span>                          
                            </div>
                          </CardBody>                      
                        </Card>                      
                      </Col>              
                      <Col sm="12">
                        <div className="text-center mb-4">
                          <h5>Resultado dos últimos 12 meses</h5>
                        </div>
                      </Col> 
                      <Col sm="6" className="mt-2">
                        <Card className="o-hidden">
                          <CardBody className="card-body statistics-wrapper">
                            <div className="statistics-header">
                              <div className="widget-icon bg-custom-light custom-success"><i className="icofont icofont-bill-alt font-success"></i></div>
                              <h6 className="statistic-name">Rendimento total</h6>
                            </div>
                            <div className="statistics-footer">
                              <h5>+ <span className="counter">{formatPrice(strategy.last_year_income / 100)}</span></h5>
                              <span className="font-sm color-gray text-bold">Resultado total do período</span>                          
                            </div>
                          </CardBody>                      
                        </Card>                      
                      </Col>    
                      <Col sm="6" className="mt-2">
                        <Card className="o-hidden">
                          <CardBody className="card-body statistics-wrapper">
                            <div className="statistics-header">
                              <div className="widget-icon bg-custom-light custom-success"><i className="icofont icofont-brand-target font-success"></i></div>
                              <h6 className="statistic-name">Taxa de assertividade</h6>
                            </div>
                            <div className="statistics-footer">
                              <h5><span className="counter">{strategy.last_year_assertiveness_rate / 100}%</span></h5>
                              <span className="font-sm color-gray text-bold">Resultado total do período</span>                          
                            </div>
                          </CardBody>                      
                        </Card>                      
                      </Col>       
                      <Col sm={12}>
                        <small className="mt-2">As estratégias sugeridas não garantem lucro. As estatísticas acima se referem apenas a operações reais feitas nos últimos 12 meses, portanto, não há garantia de que o resultado se repita no futuro.</small>                                                          
                      </Col>
                    </Row>                    
                  )}
                </CardBody>
              </Card>
            </Col>
          {strategy && (
            <>
              <Col sm={12}>
                <Card>
                  <CardHeader>
                    <h4>Configure o acesso</h4>
                    <span>Insira os dados de acesso da casa de apostas</span>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <FInput
                          label="Login da Betfair"
                          onBlur={onBlurCredentials}
                          disabled={params.id}
                          name="login"
                          type="email"
                        />
                      </Col>
                      <Col sm="6">
                        <FInput
                          label="Senha da Betfair"
                          onBlur={onBlurCredentials}
                          name="password"
                          type="password"
                        />
                      </Col>                  
                    </Row>                    
                  </CardBody>
                </Card>
              </Col>            
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h4>Custo do Robô</h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col sm="4">
                        <h5>Assinatura do robô</h5>
                        <h6>{formatPrice(strategyCost / 100)}/mês</h6>
                      </Col>
                      <Col sm="4">
                        <h5>Custo da estratégia</h5>
                        <h6>{formatPrice(strategy.robot_cost / 100)}/mês</h6>
                        <span>({strategy.percent_upon_profit / 100}% do lucro aferido)</span>
                      </Col>               
                      <Col sm="4">
                        <h5>Custo servidor AWS</h5>
                        <h6>R$ 0,36/hora</h6>
                        <span>Cobrado somente quando ativado o robô</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h4>Cartão de Crédito</h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {!card ? (
                        <Col sm="3">
                          <CardElement
                            options={{
                              hidePostalCode: true,
                            }}
                          />
                        </Col>                      
                      ) : (
                        <Col sm="3">
                          <div>
                            <span><b>{capitalizeFirstLetter(card.brand)}: **** **** **** {card.last4}</b></span>
                            <p style={{ color: 'gray' }}>Vence em {String(card.exp_month).padStart(2, '0')}/{card.exp_year}</p>
                          </div>
                        </Col>
                      )}
                    </Row> 
                  </CardBody>
                </Card>
             </Col>                
              <Col sm="12">
                <h4 className="pull-right">Total: {formatPrice(((strategyCost + strategy.robot_cost) / 100))}</h4>
              </Col>       
              <Col sm="12" className="d-flex justify-content-between mb-5 mt-3">
                <Button type="button" onClick={() => history.push('/robots')} outline className="btn-lg">Cancelar</Button>            
                <Button color="primary" className="btn-lg" disabled={isSaveButtonDisabled}>
                  {loading ? 'CARREGANDO...' : 'Salvar'}
                </Button>
              </Col>              
            </>
          )}
          </Row>                   
        </UnForm>
      </Container>
    </>
  );
}

const RobotForm = () => {
  return (
    <Elements stripe={stripePromise}>
      <InternalComponent />
    </Elements>    
  )
}

export default RobotForm;