import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Table, FormGroup, Nav, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { PlusCircle } from 'react-feather';
import {Link, useHistory} from 'react-router-dom'
import { useEffect } from 'react';
import api from '../../services/api';
import { formatPrice, toCents } from '../../util/format';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';
import Input from '../Input';
import { useRef } from 'react';
import FCurrencyInput from '../CurrencyInput';
import { v4 } from 'uuid'
import * as Yup from 'yup';

const Filters = (props) => {

  const [filters, setFilters] = useState([])
  const formRef = useRef()
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)

  const fetchFilters = () => {
    const userId = localStorage.getItem('@firebot:user_id')
    api.get(`admin/filters`, {
      params: {
        user_id: userId
      }
    }).then((response) => {
      setFilters(response.data.filter(item => item.user_id))
    });
  }

  const handleEdit = (filter) => {
    toggleModal()
    setTimeout(() => {
      formRef.current.setData(filter)
    }, 500)
  }

  const handleDelete = (id) => {
    Swal.fire({
      title: 'Atenção!',
      text: 'Deseja realmente remover esse gatilho de entrada?',
      icon: 'warning',
      confirmButtonText: 'Sim, remover',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
        await api.delete(`admin/filters/${id}`);
        toast.success('Gatilho removido com sucesso!')
        fetchFilters()
      }
    })
  }

  const handleSubmit = async (data, { resetForm }) => { 
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required('Obrigatório'),
        numbers: Yup.string().required('Obrigatório')
      });

      data.user_id = localStorage.getItem('@firebot:user_id')

      await schema.validate(data, {
        abortEarly: false,
      });

      setIsSubmitLoading(true);

      if (data.id) {
        await api.put(`admin/filters/${data.id}`, data);
      } else {
        await api.post('admin/filters', data);
      }

      toast.success('Operação realizada com sucesso')

      toggleModal()

      fetchFilters()

      resetForm()
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      } else {
        toast.error(err.error);
      }
    } finally {
      setIsSubmitLoading(false)
    }
  }

  const toggleModal = () => setIsModalOpened(!isModalOpened);

  useEffect(() => {
    fetchFilters()
  }, [])
  
  return (
    <Fragment>
      <Modal isOpen={isModalOpened} toggle={toggleModal}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>Gatilho de entrada</ModalHeader>
          <ModalBody>
            <Input
              name="name"
              label="Nome do gatilho"
            />
            <Input
              name="numbers"
              label="Números do gatilho"
            />
            <p>
              Informe os números separados por vígula.
              <br />
              Ex.: 2,10,17,31
            </p>
            <Input type="hidden" name="id" style={{ height: '0', width: '0' }} />
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>Cancelar</Button>{' '}
            <Button color="primary" disabled={isSubmitLoading} type="submit">
              { isSubmitLoading ? 'CARREGANDO...' : 'Salvar' }
            </Button>            
          </ModalFooter>          
        </Form>
      </Modal>          
      <Breadcrumb parent="Robô de Cassino" title="Gatilhos de Entrada" />
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  <Nav tabs className="border-tab">
                  </Nav>
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Button color="primary" onClick={toggleModal}> <PlusCircle />Criar gatilho</Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
              <Card>
                  <div className="table-responsive">
                      <Table  size="lg">
                          <thead>
                              <tr>
                                  <th width="300" scope="col">Nome</th>
                                  <th width="300" scope="col">Números</th>
                                  <th width="200" scope="col"></th>
                              </tr>
                          </thead>
                          <tbody>
                              {filters.map(filter => (
                                <tr key={filter.id}>
                                  <td title={filter.name}>
                                    {filter.name.length > 30 ? `${filter.name.slice(0, 30)}...` : filter.name}
                                  </td>
                                  <td>{filter.numbers}</td>
                                  <td align='right' style={{ verticalAlign: 'middle', minWidth: '266px' }}>
                                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                                      <Button
                                        outline
                                        type="primary"
                                        color="primary"
                                        onClick={() => handleEdit(filter)}
                                      >
                                        Editar
                                      </Button>
                                      <Button
                                        type="secondary"
                                        color="danger"
                                        onClick={() => handleDelete(filter.id)}
                                      >
                                        Apagar
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                      </Table>
                  </div>
              </Card>
          </Col>
      </Row>        
      </Container>
    </Fragment>
  );
}

export default Filters;