import React from 'react';
import Profile from './profile';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Account = () => {
  return (
    <Elements stripe={stripePromise}>
      <Profile />
    </Elements>
  );
}

export default Account;