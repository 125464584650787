import React from 'react'
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store'

import AppProvider from './hooks';

import Routes from './routes'

const Root = () =>  {
  return(
      <AppProvider>
        <Provider store={store}>
          <Routes />
        </Provider>          
      </AppProvider>
    )
}

ReactDOM.render(<Root/>,
  document.getElementById('root')
);

serviceWorker.unregister();
