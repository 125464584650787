import React,{useState,useEffect, useRef} from 'react';
import {Container,Row,Col,Label,Button, Input as ReactstrapInput} from 'reactstrap'
import {Link, withRouter} from 'react-router-dom'
import Input from '../components/Input';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import {toast, ToastContainer} from 'react-toastify'
import { useAuth } from '../hooks/auth';

const Logins = (props) => {
    const { signIn } = useAuth()

    const formRef = useRef(null);
    const [loading,setLoading] = useState(false) 
    const [togglePassword,setTogglePassword] = useState(false);

    const onSubmit = async (data) => {
      try {
        const schema = Yup.object().shape({
          email: Yup.string().email('E-mail inválido').required('Obrigatório'),
          password: Yup.string().required('Obrigatório')
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);

        await signIn({
          email: data.email,
          password: data.password,
        });
  
        props.history.push('/robots');
        
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const validationErrors = {};
          if (err instanceof Yup.ValidationError) {
            err.inner.forEach(error => {
              validationErrors[error.path] = error.message;
            });
            formRef.current.setErrors(validationErrors);
          }
        } else {
          setLoading(false);
          toast.error(err.error);
        }        
      } finally {
        setLoading(false);
      }
    }

    return (
      <Container fluid={true} className="p-0">
        <ToastContainer />
        <Row>
          <Col xs="12">     
            <div className="login-card">
              <div>
                <div>
                  <a className="logo">
                    <img className="img-fluid for-light" src={require("../assets/images/logo/logo-light.svg")} alt="" width="200px" />
                  </a>
                </div>
                <div className="login-main login-tab"> 
                  <Form onSubmit={onSubmit} ref={formRef} className="theme-form">
                    <h4>Login</h4>
                    <p>{"Entre com seu login e senha"}</p>
                    <Input name="email" type="email" label="E-mail" />
                    <Input name="password" type={togglePassword ?  "text" : "password"} label="Senha">
                      <div className="show-hide" onClick={() => setTogglePassword(!togglePassword)}>
                        <span className={togglePassword ? "" : "show"}></span>
                      </div>
                    </Input>
                    <div className="form-group mb-3">
                      <div className="checkbox ml-3">
                        <ReactstrapInput id="checkbox1" type="checkbox"/>
                        <Label className="text-muted" for="checkbox1">Lembrar</Label>
                      </div>
                      <Link className="link" to="/auth/password/forget">Esqueci minha senha</Link>   
                    </div>
                      <Button color="primary" className="btn-block" disabled={loading}>{loading ? "CARREGANDO..." : "Entrar" }</Button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
}

export default withRouter(Logins);