import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Table, FormGroup, Nav, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { PlusCircle } from 'react-feather';
import {Link, useHistory} from 'react-router-dom'
import { useEffect } from 'react';
import api from '../../services/api';
import { formatPrice, toCents } from '../../util/format';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { format, parseISO } from 'date-fns';
import { Form } from '@unform/web';
import Input from '../Input';
import { useRef } from 'react';
import FCurrencyInput from '../CurrencyInput';
import { v4 } from 'uuid'
import * as Yup from 'yup';
import { capitalizeFirstLetter } from '../account/profile';

const Transactions = (props) => {

  const [transactions, setTransactions] = useState([])
  const formRef = useRef()
  const [isModalOpened, setIsModalOpened] = useState(false)
  const [isSubmitLoading, setIsSubmitLoading] = useState(false)
  const [card, setCard] = useState(null);

  const fetchTransactions = () => {
    const userId = localStorage.getItem('@firebot:user_id')
    api.get(`transactions/${userId}`).then((response) => {
      setTransactions(response.data);
    });
  }

  const handleSubmit = async (data) => { 
    try {
      const schema = Yup.object().shape({
        amount: Yup.number().positive('Deve ser um número positivo').required('Obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      setIsSubmitLoading(true);

      await api.post('users/add-credit', {
        amount: toCents(data.amount)
      })

      toast.success('Crédito adicionado com sucesso')

      toggleModal()

      fetchTransactions()
      
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach(error => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
        }
      } else {
        toast.error(err.error);
      }
    } finally {
      setIsSubmitLoading(false)
    }
  }

  const toggleModal = () => setIsModalOpened(!isModalOpened);

  const openModal = () => {
    if (!card) {
      toast.error('Você ainda não possui um cartão de crédito cadastrado')
      return
    }
    toggleModal()
  }

  useEffect(() => {
    fetchTransactions()
    api.get('/users/info/me').then(response => {
      setCard(response.data.card)
    })
  }, [])
  
  return (
    <Fragment>
      <Modal isOpen={isModalOpened} toggle={toggleModal}>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalHeader toggle={toggleModal}>Adicionar crédito</ModalHeader>
          <ModalBody>
            <FCurrencyInput
              name="amount"
              label="Valor"
            />
            {card && (
              <div>
                <span><b>{capitalizeFirstLetter(card.brand)}: **** **** **** {card.last4}</b></span>
                <p style={{ color: 'gray' }}>Vence em {String(card.exp_month).padStart(2, '0')}/{card.exp_year}</p>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" outline onClick={toggleModal}>Cancelar</Button>{' '}
            <Button color="primary" disabled={isSubmitLoading} type="submit">
              { isSubmitLoading ? 'CARREGANDO...' : 'Enviar' }
            </Button>            
          </ModalFooter>          
        </Form>
      </Modal>          
      <Breadcrumb parent="Conta Firebet" title="Extrato financeiro" />
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  <Nav tabs className="border-tab">
                  </Nav>
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Button color="primary" onClick={openModal}> <PlusCircle />Adicionar crédito</Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
              <Card>
                  <div className="table-responsive">
                      <Table  size="lg">
                          <thead>
                              <tr>
                                  <th scope="col">Data</th>
                                  <th scope="col">Serviço</th>
                                  <th scope="col">Valor</th>
                                  <th scope="col">Saldo geral</th>
                              </tr>
                          </thead>
                          <tbody>
                              {transactions.map(transaction => (
                                <tr key={v4()}>
                                  <td>{format(parseISO(transaction.cr_at), 'dd/MM/yyyy HH:mm:ss')}</td>
                                  <td>{transaction.description}</td>
                                  <td>
                                    {transaction.day_amount > 0 ? <span class="txt-success">{formatPrice(transaction.day_amount/100)}</span> : <span class="txt-danger">{formatPrice(transaction.day_amount/100)}</span>}
                                  </td>
                                  <td>
                                  {transaction.balance > 0 ? <span class="txt-success">{formatPrice(transaction.balance/100)}</span> : <span class="txt-danger">{formatPrice(transaction.balance/100)}</span>}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                      </Table>
                  </div>
              </Card>
          </Col>
      </Row>        
      </Container>
    </Fragment>
  );
}

export default Transactions;