import React, { Fragment, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, Table, FormGroup, Nav, Button } from 'reactstrap'
import { PlusCircle } from 'react-feather';
import {Link, useHistory} from 'react-router-dom'
import { useEffect } from 'react';
import api from '../../services/api';
import { formatPrice } from '../../util/format';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

const Strategies = (props) => {

  const [strategies, setStrategies] = useState([])
  const history = useHistory();

  const fetchStrategies = () => {
    api.get('strategies/created-by-current-user?status=validated')
      .then(response => setStrategies(response.data));
  }

  useEffect(() => {
    fetchStrategies()
  }, [])

  const goToEdit = (id) => {
    history.push(`/strategies/form/${id}?newVersion=true`);
  }

  const archive = async (id) => {
    try {
      Swal.fire({
        title: 'Atenção!',
        text: 'Deseja realmente remover essa estratégia?',
        icon: 'warning',
        confirmButtonText: 'Sim, remover',
        showCancelButton: true,
        cancelButtonText: 'Cancelar'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api.post(`strategies/${id}/archive`)
          toast.success('Estratégia removida com sucesso!')
          fetchStrategies()
        }
      })
    } catch (error) {
      toast.error('Falha ao excluir estratégia:', error.error)
    }
  }
  
  return (
    <Fragment>
      <Breadcrumb parent="Robô de Cassino" title="Minhas estratégias" />
      <Container fluid={true}>
        <Row>
          <Col md="12" className="project-list">
            <Card>
              <Row>
                <Col sm="6">
                  <Nav tabs className="border-tab">
                    {/* <NavItem><NavLink className={activeTab === "1" ? "active" : ''} onClick={() => setActiveTab("1")}><Target />Todos</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "2" ? "active" : ''} onClick={() => setActiveTab("2")}><Info />Rodando</NavLink></NavItem>
                    <NavItem><NavLink className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><CheckCircle />Desativado</NavLink></NavItem> */}
                  </Nav>
                </Col>
                <Col sm="6">
                  <div className="text-right">
                    <FormGroup className="mb-0 mr-0"></FormGroup>
                    <Link className="btn btn-primary" style={{ color: 'white' }} to="/strategies/form"> <PlusCircle />Criar nova estratégia</Link>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
              <Card>
                  <div className="table-responsive">
                      <Table  size="lg">
                          <thead>
                              <tr>
                                  <th scope="col">Nome</th>
                                  <th scope="col">Capital Mínimo</th>
                                  <th scope="col"></th>
                              </tr>
                          </thead>
                          <tbody>
                              {strategies.map(strategy => (
                                <tr>
                                  <td>{strategy.name}</td>
                                  <td>{formatPrice(strategy.minimum_capital/100)}</td>
                                  <td align='right' style={{ verticalAlign: 'middle', minWidth: '266px' }}>
                                    <div style={{ display: 'flex', gap: '8px', justifyContent: 'flex-end' }}>
                                      <Button outline color="primary" onClick={() => goToEdit(strategy.id)}>Editar</Button>
                                      <Button color="danger" onClick={() => archive(strategy.id)}>Excluir</Button>
                                    </div>
                                  </td>
                                </tr>        
                              ))}
                          </tbody>
                      </Table>
                  </div>
              </Card>
          </Col>
      </Row>        
      </Container>
    </Fragment>
  );
}

export default Strategies;