import React, { Fragment, useEffect, useState } from 'react';
import Breadcrumb from '../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, Table } from 'reactstrap'
import { useParams } from 'react-router-dom'
import api from '../../services/api';
import { format, parseISO } from 'date-fns'; 
import { formatPrice } from '../../util/format';

const FinancialReport = () => {

  const params = useParams();
  const [histories, setHistories] = useState([])

  useEffect(() => {
    api.get(`/robots/${params.id}/history`).then(response => {
      setHistories(response.data)
    })
  }, [params.id])

  return (
    <Fragment>
      <Breadcrumb parent="Meus robôs" title={`Relatório do robô #${params.id}`} />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
              <Card>
                <CardHeader>
                  <h5>Histórico de rendimentos</h5>
                </CardHeader>                
                  <div className="table-responsive">
                      <Table  size="lg">
                          <thead>
                            <tr>
                              <th scope="col">Data</th>
                              <th scope="col">Plataforma</th>
                              <th scope="col">Conta</th>
                              <th scope="col">Balanço</th>
                              {/* <th scope="col">Saldo</th> */}
                            </tr>
                          </thead>
                          <tbody>
                              {histories.map(history => (
                                <tr key={history.id}>
                                  <th scope="row">{format(parseISO(history.day), 'dd/MM/yyyy')}</th>
                                  <td>{history.platform} - Roleta Brasileira</td>
                                  <td>{history.login}</td>
                                  <td>
                                    {history.day_profit > 0 ? <span class="txt-success">{formatPrice(history.day_profit / 100)}</span> : <span class="txt-danger">{formatPrice(history.day_profit / 100)}</span>}
                                  </td>
                                  {/* <td>{formatPrice(history.balance / 100)}</td> */}
                                </tr>
                              ))}
                          </tbody>
                      </Table>
                  </div>
              </Card>
          </Col>
      </Row>        
      </Container>
    </Fragment>
  );
}

export default FinancialReport;